export const VehicleColorDefinitions = [
  { 
    value: 'red'
  },
  { 
    value: 'white'
  },
  { 
    value: 'blue'
  },
  { 
    value: 'yellow'
  },
  { 
    value: 'green'
  },
  { 
    value: 'lightGray'
  },
  { 
    value: 'darkGray'
  }
]