export const JournalDefinitions = [
  {
    value: "inout",
    details: "Xirgocam is powered on and has video from BOTH the inside and the outside camera during this period"
  },
  {
    value: "out",
    details: "Xirgocam is powered on and has video from ONLY the outside camera during this period"
  },
  {
    value: "guard",
    details: "Xirgocam is powered on, but there is no video during this period"
  },
]