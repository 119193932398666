import React from 'react'
import Badge from 'react-bootstrap/Badge'

export default function ReleaseNoteSection({ id, releaseName, releaseDate, summary, newFeatures, backcompatIssues, keyBugfixes, isLatest, isDeprecated, isUnsupported, isPreview }) {
  return (
    <React.Fragment>
      <hr />
      <h2 id={id} style={{ display: 'inline', verticalAlign: 'top' }} className='model-name'>Release {releaseName} </h2>
      {isLatest && <Badge pill className='primary-color-background'>Latest</Badge>}
      {isPreview && <Badge pill className='light-primary-color-background'>Preview</Badge>}
      {isDeprecated && <Badge pill variant="warning">Deprecated</Badge>}
      {isUnsupported && <Badge pill variant="danger">Not Supported</Badge>}


      <p>Released: {releaseDate}</p>
      <h4>Summary</h4>
      <p>{summary}</p>
      <h4>New Features</h4>
      <ul>
        {newFeatures && newFeatures.length > 0 ?
          newFeatures.map((feature, i) => {
            return (<li key={i}>{feature}</li>)
          })
          : <li>N/A</li>}
      </ul>

      <h4>Non-backward Compatible Changes</h4>
      <p>The following requires changes when upgrading to this version</p>
      <ul>
        {backcompatIssues && backcompatIssues.length > 0 ?
          backcompatIssues.map((feature, i) => {
            return (<li key={i}>{feature}</li>)
          })
          : <li>N/A</li>}
      </ul>
      <h4>Key Bugs Fixed</h4>
      <ul>
        {keyBugfixes && keyBugfixes.length > 0 ?
          keyBugfixes.map((feature, i) => {
            return (<li key={i}>{feature}</li>)
          })
          : <li>N/A</li>}
      </ul>
    </React.Fragment>
  )
}
