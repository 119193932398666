// REACT
import React from 'react';
// REACT ROUTER
import { Redirect } from "react-router-dom";
// COMPONENT
import DefinitionSection from '../app/components/Definitions/DefinitionSection';
// DEFINITIONS
import { ClipTypeDefinitions } from '../definitions/ClipTypes.definition';
import { AudioModeDefintions } from '../definitions/AudioMode.definition';
import { EnableBeepsDefinitions } from '../definitions/EnableBeeps.definition';
import { enableCANDefinitions } from '../definitions/EnableCAN.definition';
import { InsideCameraModeDefinitions } from '../definitions/InsideCameraMode.definition';
import { OutsideCameraResDefinitions } from '../definitions/OutsideCameraRes.definition';
import { OkPrestoModeDefintions } from '../definitions/OkPrestoMode.definition';
import { StandbyTimeDefinitions } from '../definitions/StandbyTime.definition';
import { VehicleTypeDefinitions } from '../definitions/VehicleType.definition';
import { VideoBufferSecDefinitions } from '../definitions/VideoBufferSec.definition';
import { VehicleColorDefinitions } from '../definitions/VehicleColor.definitions';
import { EnableMotionClipsDefinitions } from '../definitions/EnableMotionClips.definitions';
import { ClipMediaDefinitions } from '../definitions/ClipMedia.definition';
import { DiagnosticDefinitions } from '../definitions/Diagnostic.definition';
import { TelemetryDefinitions } from '../definitions/Telemetry.definition';
import TitleBlock from '../app/components/Titles/TitleBlock';
import { JournalDefinitions } from '../definitions/Journal.definition';
import { CameraDefinitions } from '../definitions/Camera.definition';
import { TripCANDefinitions, TripGPSDefinitions } from '../definitions/Trip.definition';
import { CameraModeDefinitions } from '../definitions/CameraMode.definition';
import { DefinitionTableContentR2 } from '../tableContentLists/r2/definitions.tableContentR2';
import TableContentListWithSections from '../app/components/TableContent/TableContentListWithSections';


const Definitions = ({ version }) => {
  if (version === 1) {
    return <Redirect to="/" />
  }
  return (
    <div className="container-fluid">
      <div className="flex-xl-nowrap row">
        <TableContentListWithSections contentSections={DefinitionTableContentR2} />
        <main
          className="col-12 col-md-9 col-xl-10 py-md-3 pl-md-5 bd-content"
          role="main"
          id="main-definitions">

          <TitleBlock
            title={'Definitions'}
            release={version} />

          <p className="bd-lead">
            The following reference is a detailed explanation of how the various enumerartions and definitions are used by Xirgocam. This information is in addition to the schema to better understand how and when to use specific values.
          </p>
          <hr />
          <h2 id="camera" className="model-name">Camera</h2>
          <DefinitionSection
            id={"cameraState"}
            title={'Camera State'}
            body={'When Xirgocams ship from the factory they are in the allocated state. In order to use the Xirgocam, it must first be activated'}
            definitions={CameraDefinitions}
          />
          <DefinitionSection
            id={'cameraMode'}
            title={'Camera Mode'}
            body={'Camera mode refers to the current operating mode of the Xirgocam. The camera mode is separate from camera state as detailed above.  Xirgocam can be in one of three modes:'}
            definitions={CameraModeDefinitions}
          />
          <hr />
          <h2 id="clips" className="model-name">Clips</h2>
          <p>Xirgocam will create clips under a vartiey of conditions. For example, Xirgocam will create clips manually when the driver explicity says "Ok Presto" or taps the screen. Xirgocam will automatically generate clips when important incidents are detected, such as collisions and harsh driving incidents.</p>

          <DefinitionSection
            id={'clipTypes'}
            title={'Clip Types'}
            body={'Defines the type of clip created'}
            definitions={ClipTypeDefinitions} />

          <DefinitionSection
            id={'clipMedia'}
            title={'Clip Media'}
            body={'Clips can contain a variety of associated media. The following details possible values'}
            definitions={ClipMediaDefinitions} />
          <DefinitionSection
            id={'motionClip'}
            title={'Motion Clip'}
            body={'Motion clips are generated when the Xirgocam detects one of the following events: harshBrake, harshAccel, hardCornerLeft and hardCornerRight. The motion clip system accuracy is enhanced when the Xirgocam has the VIN for the vehicle it is installed. The VIN can be provided through API call or the CAN data system. For more information on CAN data system, please see below.'}
            definitions={[]}
          />
          <hr />

          <h2 id='settings' className='model-name' style={{ marginTop: '36px' }}>Settings</h2>

          <DefinitionSection
            id={'audioMode'}
            title={'Audio Mode'}
            codeName={'audioMode'}
            body={'Defines how the microphones can be controlled by the driver'}
            definitions={AudioModeDefintions} />

          <DefinitionSection
            id={'insideCameraMode'}
            codeName={'insideCameraMode'}
            title={'Inside Camera Mode'}
            body={'Defines how the driver can control the inside camera of the Xirgocam'}
            definitions={InsideCameraModeDefinitions} />

          <DefinitionSection
            id={'okPrestoMode'}
            codeName={'okPrestoMode'}
            title={'OK Presto Mode'}
            body={'Xirgocam is always listening for the driver to say the keyword of \'Ok Presto\'. When Xirgocam detects this keyword has been spoken, the following setting defines how the Xirgocam will respond'}
            definitions={OkPrestoModeDefintions} />

          <DefinitionSection
            id={'videoBufferMode'}
            codeName={'videoBufferMode'}
            title={'Video Buffer Mode'}
            body={'Defines the limits for video storage. Xirgocam can be configured to use the maximum amount of space or can be limited to be a subset of the internal memory, by time'}
            definitions={VideoBufferSecDefinitions} />

          <DefinitionSection
            id={'outsideRes'}
            codeName={'outsideRes'}
            title={'Oustide Camera Resolution'}
            body={'Defines the recording resolution of the outside camera of the Xirgocam. Inside camera resolution is fixed at 720p (1280x720)'}
            definitions={OutsideCameraResDefinitions} />

          <DefinitionSection
            id={'standbyTime'}
            codeName={'standbyTime'}
            title={'Standby Time'}
            body={'Xirgocam monitors the vehicle\'s battery as well as how much power has been consumed by Xirgocam between vehicle start events. Xirgocam will power down automatically after a specified max amount of time if the vehicle has not been started or if the Xirgocam determines that the vehicle battery is too low. This setting defines the behavior for the max number of hours that the Xirgocam will stay in guard mode before shutting down to preserve the vehicle\'s battery'}
            definitions={StandbyTimeDefinitions} />

          <DefinitionSection
            id={'enableBeeps'}
            title={'Enable Beeps'}
            codeName={'enableBeeps'}
            body={'Defines the camera behavior for playing audible sounds to the driver'}
            definitions={EnableBeepsDefinitions} />

          <DefinitionSection
            id={'enableCAN'}
            codeName={'enableCAN'}
            title={'Enable CAN (Car Area Network) data'}
            body={'For drivers with the OBD-VIN power cable, CAN (Car Area Network) data may be available, depending on the vehicle. The setting is an override to enable or disable the CAN bus data from being read'}
            definitions={enableCANDefinitions} />

          <DefinitionSection
            id={'enableMotionClips'}
            title={'Enable Motion Clips'}
            codeName={'enableMotionClips'}
            body={'Defines whether clips are generated from harsh/hard motion experience by the Xirgocam. Xirgocam can be configured to automatically generate a clip when a harsh/hard event is automatically detected by Xirgocam'}
            definitions={EnableMotionClipsDefinitions} />
          <hr />

          <DefinitionSection
            id={'vehicleColor'}
            codeName={'vehicleColor'}
            title={'Vehicle Color'}
            body={'Defines the vehicle color that has the installed Xirgocam'}
            definitions={VehicleColorDefinitions} />

          <DefinitionSection
            id={'vehicleName'}
            codeName={'vehicleName'}
            title={'Vehicle Name'}
            body={'Defines a nickname for the vehicle with the installed Xirgocam'}
            definitions={[]} />

          <DefinitionSection
            id={'vehicleType'}
            title={'Vehicle Type'}
            codeName={'vehicleType'}
            body={'Defines the type of vehicle with the installed Xirgocam'}
            definitions={VehicleTypeDefinitions} />
          <hr />

          <h2 id='telemetry' className='model-name' style={{ marginTop: '36px' }}>Telemetry</h2>
          <p>Xirgocam provides location and time-based information about the vehicle. This information comes primarily from GPS, but can also include CAN data. For more information, see "CAN Data", mentioned above. Fields prefixed with "can" will read data directly from the CAN bus. See specific fields for limitations. For more information, review the Telemetry model <a href="/xirgocam-api#model-Camera">here</a></p>
          <DefinitionSection
            title={''}
            body={''}
            definitions={TelemetryDefinitions} />
          <hr />

          <h2 id='can' className='model-name' style={{ marginTop: '36px' }}>CAN Data</h2>
          <p>Xirgocam can read CAN bus data from vehicles such as fuel-level, engine RPM, and vehicle-reported speed. There are three requirements to use this functionality</p>
          <ol>
            <li>Xirgocam installed with the OBD-VIN power cable</li>
            <li>Vehicle complies with UDS data standard in accordance with J1979 (typically USA cars/trucks produced after 2007)</li>
            <li>Setting for <code>enableCAN</code> is set to <code>true</code></li>
          </ol>
          <hr />
          <h2 id='diagnostics' className='model-name' style={{ marginTop: '36px' }}>Diagnostics</h2>
          <DefinitionSection
            title={'Vehicle Diagnostic Information'}
            body={'Xirgocam can read a variety of information for vehicle DTC (diagnostic troubleshooting codes) from vehicles over the CAN bus when using the OBD-VIN power cable'}
            links={[
              'https://en.wikipedia.org/wiki/OBD-II_PIDs',
              'https://en.wikipedia.org/wiki/On-board_diagnostics#EOBD_fault_codes'
            ]}
            definitions={DiagnosticDefinitions} />
          <hr />

          <h2 id='journalEntry' className='model-name'>Journal Entry</h2>
          <DefinitionSection
            title={'Journal State'}
            body={'Journal entries can be in one of the following 3 states:'}
            definitions={JournalDefinitions}
          />
          <hr />

          <h2 id='trips' className='model-name' style={{ marginTop: '36px' }}>Trips</h2>
          <p>Xirgocam can identify and track trips and provide information in the form of a timeline of data points similar to Telemetry.</p>
          <DefinitionSection
            title={''}
            body={'Xirgocam requires network connection and GPS signal to retrieve and update GPS data. For more information on these fields, review the Telemetry section above.'}
            definitions={TripGPSDefinitions} />
          <DefinitionSection
            title={''}
            body={'The following fields are available when the Xirgocam has CAN data. For more information, review CAN Data and Telemetry sections above.'}
            definitions={TripCANDefinitions}
          />
          <br />
          <p>When trip information is uploaded, there is a webhook event triggered. For more information about the 'trip' webhook, please click <a href="/events-api#operations-tag-trip">here</a>.</p>
          <hr />
        </main>
      </div>
    </div>
  )
}
export default Definitions;