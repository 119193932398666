// REACT + ROUTER
import React from 'react';
import { Link } from 'react-router-dom';

// BOOTSTRAP COMPONENTS

// STYLES
import '../../styles/components/Navbar.styles.css'


function NavbarLink({ title, path, currentPath, seperatorAfter }) {
  let className = path === currentPath ? 'navbar-link active' : 'navbar-link';
  let style = seperatorAfter ? { borderRight: '5px solid #999' } : {};

  return (
    <Link style={style} to={path} className={className}>{title}</Link>
  )
}

function NavbarLinks({ currentPath, displayDemoLink, displayDefinitionsLink }) {
  return (
    <React.Fragment>
      <NavbarLink title={'XirgocamAPI'} path={'/xirgocam-api'} currentPath={currentPath} />
      <NavbarLink title={'CameraAPI'} path={'/camera-api'} currentPath={currentPath} />
      <NavbarLink title={'Webhooks'} path={'/events-api'} currentPath={currentPath} />
      {displayDefinitionsLink && (
        <NavbarLink title={'Definitions'} path={'/definitions'} currentPath={currentPath} />
      )}
      {displayDemoLink && (
        <NavbarLink title={'Demo'} path={'/portal'} currentPath={currentPath} />
      )}
      <NavbarLink title={'Release Notes'} path={'/release-notes'} currentPath={currentPath} />

    </React.Fragment>
  );
}

export default NavbarLinks;
