import React from "react";

// SWAGGER
import SwaggerUI from "swagger-ui-react";
// import "swagger-ui-react/swagger-ui.css";
import { swaggerVersionSelector } from "../utils/swaggerVersionSelector";

// COMPONENT
import TableContentList from "../app/components/TableContent/TableContentList";
import TitleBlock from "../app/components/Titles/TitleBlock";


class XirgocamAPI extends React.Component {
  state = { url: null, version: this.props.version };

  async updateComponent(version) {
    const url = await swaggerVersionSelector(version, "xirgocam");
    this.setState({ url, version })
  }

  async componentDidMount() {
    this.updateComponent(this.props.version);
  }

  async componentDidUpdate() {
    const { version } = this.props
    if (this.state.version !== version) {
      this.updateComponent(version);
    }
  }

  render() {
    return (
      <div className="row">
        <TableContentList version={this.props.version} name={"xirgocam"} />
        <main className="col-12 col-md-9 col-xl-10 py-md-3 pl-md-5 bd-content clearcolfmt" role="main">
          <TitleBlock
            title="Xirgocam API"
            release={this.state.version}
          />
          <SwaggerUI
            url={this.state.url}
            docExpansion="list"
            className="padding-16"
          />
        </main>
      </div>
    );
  }
}

export default XirgocamAPI;
