// REACT + ROUTER
import React from "react";
import { Link, withRouter } from "react-router-dom";

// BOOTSTRAP COMPONENTS
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
/*import NavDropdown from "react-bootstrap/NavDropdown";*/

// APP COMPONENTS
import NavbarLinks from "./NavbarLinks";
import NavbarVersionControl from "./NavbarVersionControl";

// ASSETS
import XirgocamLogo from "../Logos/XirgocamLogo";
import { isValidDemoPartner } from "../../../constants/App.constants";


function NavbarComponent(props) {
  return (
    <Navbar className="topnav-bg" bg="dark" variant="dark" sticky="top" expand="md">
      <Navbar.Brand>
        <Link to="/">
          {props.location.pathname !== '/portal' ? <XirgocamLogo height={30} fill="#FFFFFF" /> : <XirgocamLogo height={30} fill="#FFFFFF00" />}
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto green">
          <NavbarVersionControl
            version={props.version}
            handleSelectVersion={props.handleSelectVersion}
            userEmail={props.userEmail}
          />
          <NavbarLinks
            path={props.location.pathname}
            displayDemoLink={isValidDemoPartner(props.partnerPoolId)}
            displayDefinitionsLink={props.version === 2}
          />
          {/* <NavDropdown title="Samples" id="basic-nav-dropdown">
            <NavDropdown.Item href="">--Coming Soon--</NavDropdown.Item>
          </NavDropdown> */}
        </Nav>
        <Nav pullright="true">
          <Nav.Link className="disabled">{props.userEmail}</Nav.Link>
          <Button
            size="sm"
            onClick={props.handleSignOut}
            className="navbar-button"
          >
            Sign out
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default withRouter(props => <NavbarComponent {...props} />);
