export const DiagnosticDefinitions = [
  { 
    value: "dtcCycle", 
    details: 'Emission-related diagnostic trouble codes detected during current or last completed driving cycle. It enables the external test equipment to obtain "pending" diagnostic trouble codes detected during current or last completed driving cycle for emission-related components/systems. This is used by service technicians after a vehicle repair, and after clearing diagnostic information to see test results after a single driving cycle to determine if the repair has fixed the problem' 
  },
  { 
    value: "dtcConfirmed", 
    details: 'Emission-related "confirmed" diagnostic trouble codes stored'
  },
  { 
    value: "dtcPermanent", 
    details: 'Emission-related "permanent" diagnostic trouble codes stored. As per CARB, any diagnostic trouble codes that is commanding MIL on and stored into non-volatile memory shall be logged as a permanent fault code'
  }
]