export const DefinitionTableContentR2 = [
  {
    id: "#camera",
    name: "Camera",
    list: [
      {
        id: "#cameraState",
        name: "Camera State"
      },
      {
        id: "#cameraMode",
        name: "Camera Mode"
      },
    ]
  },
  {
    id: "#clips",
    name: "Clips",
    list: [
      {
        id: "#clipTypes",
        name: "Clip Types"
      },
      {
        id: "#clipMedia",
        name: "Clip Media"
      },
      {
        id: "#motionClip",
        name: "Motion Clip"
      }
    ]
  },
  {
    id: "#settings",
    name: "Settings",
    list: [
      {
        id: "#audioMode",
        name: "Audio Mode"
      },
      {
        id: "#insideCameraMode",
        name: "Inside Camera Mode"
      },
      {
        id: "#okPrestoMode",
        name: "Ok Presto Mode"
      },
      {
        id: "#videoBufferMode",
        name: "Video Buffer Mode"
      },
      {
        id: "#outsideRes",
        name: "Outside Camera Resolution"
      },
      {
        id: "#standbyTime",
        name: "Standby Time"
      },
      {
        id: "#enableBeeps",
        name: "Enable Beeps"
      },
      {
        id: "#enableCAN",
        name: "Enable CAN"
      },
      {
        id: "#enableMotionClips",
        name: "Enable Motion Clips"
      },
      {
        id: "#vehicleColor",
        name: "Vehicle Color"
      },
      {
        id: "#vehicleName",
        name: "Vehicle Name"
      },
      {
        id: "#vehicleType",
        name: "Vehicle Type"
      },
    ]
  },
  {
    id: "#telemetry",
    name: "Telemetry",
    list: []
  },
  {
    id: "#can",
    name: "CAN Data",
    list: []
  },
  {
    id: "#diagnostics",
    name: "Diagnostics",
    list: []
  },
  {
    id: "#journalEntry",
    name: "Journal Entry",
    list: []
  },
  {
    id: "#trips",
    name: "Trips",
    list: []
  },
]