export const ClipMediaDefinitions = [
  { 
    value: "accelerometer", 
    details: "100Hz-sampled across all 3 axes during the clip duration. Present for manual, collision, glassbreak, harshBrake, harshAccel, hardCornerLeft, hardCornerRight" 
  },
  { 
    value: "gps", 
    details: "1Hz-sampled GPS data provided when available for the duration of clip"
  },
  { 
    value: "imagesInside", 
    details: "Sampled images from the inside camera during the duration of the clip. AI decides images provided and when they were taken"
  },
  { 
    value: "imagesOutside", 
    details: "Sampled images from the outside camera during the duration of the clip. AI decides images provided and when they were taken"
  },
  { 
    value: "thumbnailInside", 
    details: "Representative image of the clip event from the inside camera. If the inside camera is set to off, a thumbnail may not be available"
  },
  { 
    value: "thumbnailOutside", 
    details: "Representative image of the clip event from the outside camera. Every clip will contain this"
  },
  { 
    value: "videoInside", 
    details: "Available when inside camera is enabled"
  },
  { 
    value: "videoOutside", 
    details: "Typically available for any clip with video, except history exports of the inside camera"
  }
]