export const OutsideCameraResDefinitions = [
  { 
    value: '1440', 
    details: '1440p (2560 x 1440)' 
  },
  { 
    value: '1080', 
    details: '1080p (1920 x 1080)' 
  },
  { 
    value: '720', 
    details: '720p (1280 x 720)' 
  }
]