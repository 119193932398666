export const CameraDefinitions = [
  {
    value: 'allocated',
    details: 'Xirgocam has been provisioned for use with a partner, but is not yet ready to be used (e.g. live view)'
  },
  {
    value: 'active',
    details: 'Xirgocam is ready for use. All functionality is enabled'
  },
  {
    value: 'inactive',
    details: 'Xirgocam was previously active, but is now no longer active. All data has been deleted from the Xirgocam. The Xirgocam must be activated again before can be used. This is typically done when transferring cameras between customers'
  },
]