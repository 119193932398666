// REACT + ROUTER
import React from "react";
import PropTypes from "prop-types"

function DefinitionSection ({ title, body, definitions, id, codeName }) {
  return (
    <div id={id} style={{marginTop:'24px'}}>
      <h4>{title} {codeName && <code>{'<'}{codeName}{'>'}</code>}</h4>
      <p>{body}</p>
      {definitions.length > 0 &&
        <div className="definition-box">
          <ul className="definition-list">
            {definitions.map((definition, i) => {
              const { value, details } = definition;
              return (
                <li key={`${title}-${value}-${i}`}><code>{value}</code>{details ? ' - ' + details : ''}</li>
              )
            })}
          </ul>
        </div> 
      }
    </div>
  );
}

DefinitionSection.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  definitions: PropTypes.arrayOf(PropTypes.exact({
    value: PropTypes.string.isRequired,
    details: PropTypes.string,
  })).isRequired,
}

export default DefinitionSection;
