export const VehicleTypeDefinitions = [
  { 
    value: 'sedan'
  },
  { 
    value: 'coupe'
  },
  { 
    value: 'SUV'
  },
  { 
    value: 'van'
  },
  { 
    value: 'truck'
  },
  { 
    value: 'RV'
  },
  { 
    value: 'semi'
  }
]