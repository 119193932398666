export const StandbyTimeDefinitions = [
  { 
    value: '72', 
    details: '72 hours' 
  },
  { 
    value: '48', 
    details: '48 hours' 
  },
  { 
    value: '24', 
    details: '24 hours' 
  }
]