import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import XirgocamLogo from "./app/components/Logos/XirgocamLogo";

if (process.env.NODE_ENV !== 'development') {
  console.log = () => { };
}

ReactDOM.render(
  <div>
    <center id="loginBanner" className="topnav">
      <XirgocamLogo fill="#ffffff" height="40" />
    </center>
    <App />
    <center id="loginLowerBanner">
      <span className="text-grey">
        Don't have a login? Contact{" "}
        <a href="mailto:devops@owlcam.com">devops@owlcam.com</a>
      </span>
    </center>
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
