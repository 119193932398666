import { XirgocamTableContentListR1 } from "../tableContentLists/r1/xirgocam.tableContentR1"
import { EventTableContentListR1 } from "../tableContentLists/r1/events.tableContentR1"
import { EventTableContentR2 } from '../tableContentLists/r2/events.tableContentR2'
import { XirgocamTableContentListR2 } from "../tableContentLists/r2/xirgocam.tableContentR2"

export function tableContentSelector(version, name) {
  if (version === 1) {
    return name === "xirgocam" ? XirgocamTableContentListR1 : EventTableContentListR1;
  }
  if (version === 2) {
    return name === "xirgocam" ? XirgocamTableContentListR2 : EventTableContentR2;
  }
  return [];
}