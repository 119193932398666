// REACT + ROUTER
import React from "react";

// BOOTSTRAP COMPONENTS
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { verifyInternalUserEmail } from "../../../utils/verifyInternalUserEmail";

const INTERNAL_RELEASES = ['R1', 'R2'];
const EXTERNAL_RELEASES = ['R1', 'R2'];

function NavbarVersionControl({ version, handleSelectVersion, userEmail }) {
  let isUserAnOwl = false;
  if (userEmail) {
    isUserAnOwl = verifyInternalUserEmail(userEmail);
  }
  const versions = isUserAnOwl ? INTERNAL_RELEASES : EXTERNAL_RELEASES;

  return (
    <React.Fragment>
      <Navbar.Text style={{ color: "#FFF" }}>Release</Navbar.Text>
      <NavDropdown title={`R${version}`} id="nav-version-dropdown" onSelect={handleSelectVersion}>
        {versions.map((version, i) => {
          const eventKey = i + 1
          return (
            <NavDropdown.Item eventKey={eventKey} key={version + "-" + eventKey}>{version}</NavDropdown.Item>
          )
        })}
      </NavDropdown>
    </React.Fragment>
  );
}

export default NavbarVersionControl;
