import React from "react";
import { Auth } from "aws-amplify";

import { Redirect } from 'react-router-dom';
import { isValidDemoPartner } from "../constants/App.constants";
import { AppConfiguration } from "../configuration/App.configuration";

class DemoPortal extends React.Component {

  state = {
    token: null,
    partnerPoolId: undefined,
    redirect: false,
  };

  componentDidMount = async () => {
    console.log('componentDidMount');
    let currentSession = await Auth.currentSession();
    if (process.env.NODE_ENV === 'development') {
      console.log('token');
      console.log(currentSession.getIdToken().getJwtToken())
    }

    let currentUserInfo = await Auth.currentUserInfo();
    if (currentUserInfo.attributes['custom:partnerPoolId']) {
      const partnerPoolId = currentUserInfo.attributes['custom:partnerPoolId'];
      if (!isValidDemoPartner(partnerPoolId)) {
        this.setState({ redirect: true });
      }
    }

    this.setState({
      token: currentSession.getIdToken().getJwtToken()
    });
  }

  componentWillUnmount = () => {
    this.setState({ redirect: false });
  }

  render() {
    const { redirect, token } = this.state;

    if (redirect) {
      return <Redirect to="/" />
    }

    let demoPortalUrl = AppConfiguration.DemoPortalUrl;

    console.log('test', process.env.REACT_APP_DEMO_PORT);
    if (process.env.REACT_APP_DEMO_PORT) {
      demoPortalUrl = `http://localhost:${process.env.REACT_APP_DEMO_PORT}`;
    }

    return (
      <iframe className="demo-portal-frame" title="demo-portal" src={`${demoPortalUrl}/index.html?token=${token}`} />
    );
  }
}

export default DemoPortal;