import { Storage } from "aws-amplify";

export function swaggerVersionSelector(version, name) {
  if (version === 1) {
    return Storage.get(`${name}.swagger.json`)
      .then(file => {
        return file
      })
      .catch(() => {
        console.log("error downloading file");
      })
  } else {
    return Storage.get(`r${version}/${name}.swagger.json`)
      .then(file => {
        return file
      })
      .catch(() => {
        console.log("error downloading file")
      })
  }
}