export const ClipTypeDefinitions = [
  {
    value: "collision",
    details: "Probable severe damage to the vehicle detected. Contains video(s) and thumbnail(s)"
  },
  {
    value: "movement",
    details: "Any motion larger than light motion detected. Contains thumbnail(s)"
  },
  {
    value: "glassbreak",
    details: "Glass breaking. Contains video(s) and/or thumbnail(s) "
  },
  {
    value: "history",
    details: "Clip exported from the history buffer. Contains a video"
  },
  {
    value: "manual",
    details: 'Verbal command ("OK Presto") or tapping the Xirgocam screen. Contains video(s) and thumbnail(s)'
  },
  {
    value: "marker",
    details: "Contains thumbnail(s). Happens when okPrestoMode is set to marker or a marker is exported from history"
  },
  {
    value: "harshBrake",
    details: "[Motion Clip] Harsh braking detected. Contains thumbnail(s)"
  },
  {
    value: "harshAccel",
    details: "[Motion Clip] Harsh acceleration detected. Contains thumbnail(s)"
  },
  {
    value: "hardCornerLeft",
    details: "[Motion Clip] Hard left-turn cornering detected. Contains thumbnail(s)"
  },
  {
    value: "hardCornerRight",
    details: "[Motion Clip] Hard right-turn cornering detected. Contains thumbnail(s)"
  },
]