export const XirgocamTableContentListR2 = [
  {
    id: "#operations-tag-admin",
    name: "Admin"
  },
  {
    id: "#operations-tag-camera",
    name: "Camera"
  },
  {
    id: "#operations-tag-camera_activation",
    name: "Camera Activation"
  },
  {
    id: "#operations-tag-camera_credits",
    name: "Camera Credits"
  },
  {
    id: "#operations-tag-camera_settings",
    name: "Camera Settings"
  },
  {
    id: "#operations-tag-camera_telemetry",
    name: "Camera Telemetry"
  },
  {
    id: "#operations-tag-camera_vehicle",
    name: "Camera Vehicle"
  },
  {
    id: "#operations-tag-clips",
    name: "Clips"
  },
  {
    id: "#operations-tag-credits",
    name: "Credits"
  },
  {
    id: "#operations-tag-diagnostics",
    name: "Diagnostics"
  },
  {
    id: "#operations-tag-history",
    name: "History"
  },
  {
    id: "#operations-tag-messaging",
    name: "Messaging"
  },
  {
    id: "#operations-tag-trips",
    name: "Trips"
  },
  {
    id: "#model-Camera",
    name: "Schemas"
  },
]