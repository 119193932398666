export const AppConfiguration = {
  "Amplify": {
    Analytics: {
      disabled: true
    },
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // EDKUserPool
      identityPoolId: 'ca-central-1:12373cba-794d-43e8-acba-aa389b0c93b3',
  
      // REQUIRED - Amazon Cognito Region
      region: 'ca-central-1',
  
      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: 'ca-central-1',
  
      // OPTIONAL - Amazon Cognito User Pool ID
      // EDK-Portal
      userPoolId: 'ca-central-1_eQqPHVdfQ',
  
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      // EDK-Web-Portal
      userPoolWebClientId: '71d18cl2aqr2p4q6c63qk0jues',
  
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true
  
      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //   domain: "portal.partner.xircamtest.xirgo.com",
      //   // OPTIONAL - Cookie path
      //   path: "/",
      //   // OPTIONAL - Cookie expiration in days
      //   expires: 365,
      //   // OPTIONAL - Cookie secure flag
      //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //   secure: true
      // }
  
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      //authenticationFlowType: "USER_PASSWORD_AUTH"
    },
    Storage: {
      AWSS3: {
        bucket: 'xirgo-external-partner-edk-portal', //REQUIRED -  Amazon S3 bucket
        region: 'ca-central-1' //OPTIONAL -  Amazon service region
      }
    }
  },
  "DemoPortalUrl": "https://xirgo-external-embedded-portal.s3-ca-central-1.amazonaws.com"
}