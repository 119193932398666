export const EventTableContentListR1 = [
  {
    id: "#operations-tag-clips",
    name: "Clips"
  },
  {
    id: "#operations-tag-camera_telemetry",
    name: "Camera Telemetry"
  },
  {
    id: "#operations-tag-camera_mode",
    name: "Camera Mode"
  },
  {
    id: "#model-Clip",
    name: "Schemas"
  },
]