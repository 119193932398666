import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <span className="text-muted">Owl Cameras Inc. © 2019</span>
      </div>
    </footer>
  );
}
