import React from "react";
import Footer from '../app/components/Footer/Footer.jsx';

export default function Home() {
  return (
    <React.Fragment>
      <div className="home-grid">
        <div className="features-boxed">
          <div className="container">
            <div className="intro">
              <h2 className="text-center title">Enterprise Development Kit</h2>
              <p className="text-center">
                Integrate Xirgocam technology into your product in 3 easy pieces
            </p>
            </div>
            <div className="row justify-content-center features">
              <div className="col-sm-6 col-md-5 col-lg-4 item">
                <div className="box">
                  <h3 className="name">Xirgocam API</h3>
                  <p className="description">
                    Communicate with the Xirgocam cloud for info on cameras,
                    settings, &amp; credits
                </p>
                  <a className="learn-more" href={"/xirgocam-api"}>
                    Learn more »
                </a>
                </div>
              </div>
              <div className="col-sm-6 col-md-5 col-lg-4 item">
                <div className="box">
                  <h3 className="name">Camera API</h3>
                  <p className="description">
                    Securely communicate with Xirgocams to live view, view history
                    and export clips
                </p>
                  <a className="learn-more" href="/camera-api">
                    Learn more »
                </a>
                </div>
              </div>
              <div className="col-sm-6 col-md-5 col-lg-4 item">
                <div className="box">
                  <h3 className="name">Event Webhooks</h3>
                  <p className="description">
                    Enable your cloud to recieve realtime events and notifications
                    from Xirgocam
                </p>
                  <a className="learn-more" href={"/events-api"}>
                    Learn more »
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
