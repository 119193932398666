export const TelemetryDefinitions = [
  { 
    value: "accuracy", 
    details: "Non-negative measure of accuracy of GPS-related data such as speed, latitude, and longitude. Lower is better"
  },
  { 
    value: "speed", 
    details: "Observed vehicle speed by GPS, in meters/sec. If CAN data is available, will average measured GPS speed and CAN bus reported vehicle speed" 
  },
  { 
    value: "canOdometer", 
    details: "Support for this specific data point is limited and will vary depending on the vehicle make, model, and year"
  },
  { 
    value: "canFuelPercentage", 
    details: "Values from 0-100 representing the current level of the fuel tank. Fuel level is sensitive and can fluctuate 10 percent under normal conditions"
  },
  { 
    value: "canEngineRuntime", 
    details: "Number of seconds since most recent vehicle ignition-on event"
  },
  { 
    value: "canEngineRpm", 
    details: "Engine speed"
  }
]