export const EventTableContentR2 = [
  {
    id: "#operations-tag-camera_activation",
    name: "Camera Activation"
  },
  {
    id: "#operations-tag-camera_mode",
    name: "Camera Mode"
  },
  {
    id: "#operations-tag-camera_telemetry",
    name: "Camera Telemetry"
  },
  {
    id: "#operations-tag-clips",
    name: "Clips"
  },
  {
    id: "#operations-tag-diagnostics",
    name: "Diagnostics"
  },
  {
    id: "#operations-tag-history",
    name: "History"
  },
  {
    id: "#operations-tag-vehicle",
    name: "Vehicle"
  },
  {
    id: "#operations-tag-trip",
    name: "Trip"
  },
  {
    id: "#model-Clip",
    name: "Schemas"
  },
]