import React from 'react';
import ReleaseNoteSection from '../app/components/ReleaseNotes/ReleaseNoteSection';
import { R2NewFeatures, R2BackcompatIssues } from '../releaseNotes/r2ReleaseNotes/r2ReleaseNotes';


const ReleaseNotes = () => {
  return (
    <div className="container-fluid">
      <div className="flex-xl-nowrap row">
        <div className="col-xl-2 col-md-3 col-12 d-flex flex-column sidenav sidenavitem">
          <a href="#v1">Releases</a>
          <ul>
            <li>
              <a href="#r2">R2</a>
            </li>
            <li>
              <a href="#v1">R1</a>
            </li>
          </ul>
        </div>

        <main
          className="col-12 col-md-9 col-xl-10 py-md-3 pl-md-5 bd-content"
          role="main"
          id="main-definitions">

          <h1 className="pagetitle" style={{ paddingTop: "32px" }}>Release Notes</h1>
          <p className="bd-lead">Last updated: December 6, 2019</p>
          <p>Older releases shall be supported unless otherwise specified</p>

          <ReleaseNoteSection
            isPreview={true}
            id={'r2'}
            releaseName={'R2'}
            releaseDate={'Targeting January 2020'}
            summary={'Xirgocam R2 API contains a number of new features, key enhancements from partner feedback, as well as major improvements to the API documentation and definitions'}
            newFeatures={R2NewFeatures}
            backcompatIssues={R2BackcompatIssues}
            keyBugfixes={[
              '"dateFrom" was not respected when calling /clip/camera/{cameraId}',
              'Documentation issue: setting camera (inside/outside) required when starting history playback, not after stream is set up'
            ]}
          />

          <ReleaseNoteSection
            isLatest={true}
            id={'v1'}
            releaseName={'R1'}
            releaseDate={'April 4, 2019'}
            summary={'R1 is the initial release of the Xirgocam external-facing APIs'}
            newFeatures={[
              'Xirgocam API - partner facing cloud to Xirgocam cloud data access',
              'Camera API - direct access to Xirgocam for live and history streaming ',
              'Events Webhooks - callbacks to partner cloud for realtime events',
              'Live view functionality',
              'History functionality',
              'Clips functionality']} />
        </main>
      </div>
    </div>
  )
}

export default ReleaseNotes;