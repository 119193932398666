export const R2NewFeatures = [
  "Queued offline activations: camera activations can now be completed at any time",
  "Queued offline history exports: request a history export at any time",
  "CAN bus support for J1979 standard",
  "Ignition on/off and idling",
  "Retrieve diagnostic data (DTC) from the vehicle",
  "Support for mounting the camera upside down",
  "New setting: audio recording is controlled by driver or off",
  "New setting: inside video recording is controlled by driver or off",
  "New setting: OK Prestos send a 20 sec video clip or a thumbnail",
  "New setting: set outside camera recording resolution",
  "New setting: marker clips are generated for harsh/hard motion events",
  "New telemetry info (if available): voltage, VIN, network quality, odometer, fuel percentage, engine runtime, engine speed",
  "Clip media requests now return accelerometer, GPS, and inside and outside images (if available)",
  "Motion-based events: harsh brake, harsh acceleration, hard left, and hard right",
  "History export now allows you to export thumbnails and get info on pending and processing export requests",
  "API for thumbnails: request thumbnails for a camera using an API",
  "Access trip data",
  "API that returns parsed journal",
  "New webhooks: camera activation, diagnostics, history, vehicle, and trip events"
]

export const R2BackcompatIssues = [
  'Camera Model: Removed partnerPoolId',
  'Camera Settings Model: Modifications include renaming and adding new fields',
  'Xirgocam API /history: Additional required parameters',
  'Xirgocam API /messaging: Replaced messaging token call with new streaming endpoint'
]