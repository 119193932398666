import React from "react";
import { Storage } from "aws-amplify";

// import CameraAPIR1 from "./CameraAPI.R1";
// import CameraAPIR2 from "./CameraAPI.R2";

// set to true to render the content and save locally
const STATIC_RELEASE = false;

const FILE_PREFIX = "camera-api-r"
const FILE_SUFFIX = ".html"

function getFilenameForRelease(release) {
  return FILE_PREFIX + release + FILE_SUFFIX;
}

// function getStaticModelForRelease(release) {
//   if (release === 2) {
//     return CameraAPIR2();
//   }
//   return CameraAPIR1();
// }

class CameraAPIServerLoad extends React.Component {
  state = { content: null };

  downloadContent = (release) => {
    console.log('downloadContent');
    const filename = getFilenameForRelease(release);
    console.log("filename", filename);
    Storage.get(filename, { download: true })
      .then(file => {
        console.log(file);
        document.getElementById(
          "main-camera-api"
        ).innerHTML = file.Body.toString();
      })
      .catch(() => {
        console.log("error downloading file");
        this.setState({content: 'Error loading file - please try again'});
      });
  }

  componentDidMount() {  
    this.downloadContent(this.props.version);
  }

  componentDidUpdate() {
    this.downloadContent(this.props.version);
  }


  render() {
    // if (STATIC_RELEASE) {
    //   return getStaticModelForRelease(this.props.version);
    // }
    return (
      <div id="main-camera-api" className="container-fluid">
        <div className="flex-xl-nowrap row">
          <div className="col-xl-2 col-md-3 col-12 d-flex flex-column sidenav sidenavitem">
          </div>
          <main
            className="col-12 col-md-9 col-xl-10 py-md-3 pl-md-5 bd-content"
            role="main">
            {this.state.content || "Loading... please wait"}
          </main>
        </div>
      </div>
    );
  }
}

export default CameraAPIServerLoad;
