import React from 'react'
import Badge from 'react-bootstrap/Badge'

export default function TitleBlock({ title, release }) {
  return (
    <React.Fragment>
      <div style={{ height: '32px' }}></div>
      <h1 className="pagetitle" style={{ display: 'inline' }}>{title} </h1>
      <Badge pill className='primary-color-background' style={{ verticalAlign: 'top' }}>Release R{release}</Badge>
    </React.Fragment>
  )
}
