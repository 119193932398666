export const VideoBufferSecDefinitions = [
  {
    value: 'max',
    details: 'Xirgocam will use the maximum available space for the video buffer'
  },
  {
    value: 'day',
    details: 'Xirgocam will restrict video recording to the last 24 hours, on a rolling basis At any given time, only the last 24 hours will be saved'
  },
  {
    value: 'min',
    details: 'Xirgocam will restrict video recording to the last 30 seconds, on a rolling basis. At any given time, only the last 30 seconds will be saved'
  }
]