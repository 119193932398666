export const TripGPSDefinitions = [
  {
    value: "gpsTimeStamp",
    details: ""
  },
  {
    value: "bearing",
    details: ""
  },
  {
    value: "latitude",
    details: ""
  },
  {
    value: "longitude",
    details: ""
  },
  {
    value: "speed",
    details: ""
  },
]

export const TripCANDefinitions = [
  {
    value: "canOdometer",
    details: ""
  },
  {
    value: "canFuelPercentage",
    details: ""
  },
  {
    value: "canEngineRuntime",
    details: ""
  },
  {
    value: "canEngineRpm",
    details: ""
  },
]